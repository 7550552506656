@import '../../../styles/common';

.JmApp {
  height: 100vh;
  width: 100vw;
  background-color: $appBackgroundColor;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: stretch;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: $appBorderRadius;
    background-color: $appBackgroundColor;
  }

  &::-webkit-scrollbar {
    width: 12px;
    background-color: $appBackgroundColor;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $appBorderRadius;
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: $contentBackgroundColor;
  }
}